// prettier is annoying
/* eslint-disable prettier/prettier */
import { ReactElement, useContext, useState, useEffect } from 'react';
import { ReactComponent as HostRegistrationPic } from '../../../assets/hostRegistration.svg';
import { ReactComponent as Invite } from '../../../assets/inviteGuest.svg';
import { ReactComponent as ProfilePic } from '../../../assets/profile.svg';
import { ReactComponent as RegistrationPic } from '../../../assets/registration.svg';
import { ReactComponent as SharePass } from '../../../assets/sharePass.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import Card from '../../../components/card/card';
import { useGetResidentApplication } from '../../../hooks/useGetApplication';
import style from './hostHome.module.css';

// const HOST_APPLICATION_STATUS_QRY = gql`
//   query GetHostApplicationStatus {
//     getHostApplicationStatus {
//       success
//       error
//       data {
//         createdAt
//       }
//     }
//   }
// `;

export default function HostHome(): ReactElement {
  const [hostTileData, setHostTileData] = useState({
    title: '',
    subtitle: '',
    linkURL: '',
  });
  const { communityId, featuresConfig } = useContext(CommunityContext);

  const { data, refetch } = useGetResidentApplication({
    variables: {
      type: 'host',
      userId: '',
    },
  });

  useEffect(() => {
    let newTileData = {};
    if (
      !data?.getApplication?.data ||
      data?.getApplication?.data.status == 'incomplete'
    ) {
      newTileData = {
        title: 'Host Registration',
        subtitle: 'Register as a Rental Host',
        linkURL: '/resident/host-registration',
      };
    } else {
      newTileData = {
        title: 'Rental Unit Registration',
        subtitle: 'Register or add new rental units to your “My Rentals” profile',
        linkURL: '/resident/rental-registration',
      };
    }
    setHostTileData(hostTileData => ({
      ...hostTileData,
      ...newTileData,
    }));
    refetch();
  }, [data, refetch]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        {Boolean(featuresConfig?.host?.enabled) && (
          <>
            {/* <div className={`${style.box__innerBox}`}>
              <Card
                img={<ProfilePic />}
                alt="my profile img"
                title="My Profile"
                description="Access and maintain your profile information."
                buttonLabel="Edit Info"
                href="/resident/edit-profile/my-profile?isHost=true"
                hidden={!featuresConfig?.host?.enabled}
              />
            </div> */}
            <div className={`${style.box__innerBox}`}>
              <Card
                img={<RegistrationPic />}
                alt="host registration img"
                title={hostTileData.title}
                description={hostTileData.subtitle}
                buttonLabel="Register Now"
                href={hostTileData.linkURL}
              />
            </div>
            {featuresConfig?.host?.inviteGuest?.propertyLinkEnabled
              ? (
                <div className={`${style.box__innerBox}`}>
                  <Card
                    img={<HostRegistrationPic />}
                    alt="host registration img"
                    title="My Rentals/Invite Guests"
                    description="View and manage my rentals. Invite guests to register with your unique rental unit link."
                    buttonLabel="View/Invite"
                    href="/resident/my-rentals"
                  />
                </div>
              ) : (
                <div className={`${style.box__innerBox}`}>
                  <Card
                    img={<HostRegistrationPic />}
                    alt="host registration img"
                    title="My Rentals"
                    description="View and manage my rentals."
                    buttonLabel="View"
                    href="/resident/my-rentals"
                  />
                </div>
              )}
            {Boolean(featuresConfig?.host?.inviteGuest?.enabled && !featuresConfig?.host?.inviteGuest?.propertyLinkEnabled) && (
              <div className={style.box__innerBox}>
                <Card
                  img={<Invite />}
                  alt="invite img"
                  title="Invite a Guest and Guest List"
                  description="Invite a Guest by email and/or text. Use the Guest List to manage your guest reservations."
                  buttonLabel="Invite a Guest"
                  href="/invite-guest"
                />
              </div>
            )}
            {Boolean(featuresConfig?.host?.inviteGuest?.enabled && featuresConfig?.host?.inviteGuest?.propertyLinkEnabled && !featuresConfig?.host?.inviteGuest?.guestListOnly) && (
              <div className={style.box__innerBox}>
                <Card
                  img={<Invite />}
                  alt="invite img"
                  title="Legacy Invite Guest"
                  description="Manually invite individual rental guests through our legacy process."
                  buttonLabel="Legacy Invite"
                  href="/invite-guest"
                />
              </div>
            )}
            {Boolean(featuresConfig?.host?.inviteGuest?.enabled && featuresConfig?.host?.inviteGuest?.propertyLinkEnabled) && (
              <div className={style.box__innerBox}>
                <Card
                  // img={<Invite />}
                  img={<ProfilePic />}
                  // img={<FamilyFriendsPic />}
                  // img={<GroupPic />}
                  alt="invite img"
                  title="Guest List"
                  description="View your guests. Use the Guest List to manage your guest reservations."
                  buttonLabel="View Guests"
                  href="/invite-guest/guest-list"
                />
              </div>
            )}
          </>
        )}
        {featuresConfig?.legacySingleUsePassEnabled ? (
          <div className={style.box__innerBox}>
            <Card
              img={<SharePass />}
              alt="share pass img"
              title={
                communityId === 'grandcomplex'
                  ? 'Share a One-Time Vendor Pass'
                  : 'Share a Pass'
              }
              description="You can share a single-use pass here."
              buttonLabel="Share a Pass"
              href="/resident/share-pass"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
