import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import ResidentProfileInfo, {
  newResidentProfileInfo,
} from '../common_lib_front/types/residentProfileInfo';
const GET_USER_COUMMUNITY_INFO = gql`
  query GetPropertiesByUser {
    getPropertiesByUser {
      success
      error
      data {
        state
        street
        unit
        city
        zipCode
        propertyId
      }
    }
  }
`;
export const GET_USER_PROFILE_INFO = gql`
  query GetUserInfo {
    getResidentProfile {
      success
      error
      data {
        email
        firstName
        lastName
        phoneNumber
        altPhoneNum
        mailingStreet
        mailingUnit
        mailingCity
        mailingState
        mailingCountry
        mailingZipCode
        emergencyFirstName
        emergencyLastName
        emergencyPhoneNumber
        emergencyEmail
        ownerPin
      }
    }
  }
`;
const DELETE_ADDRESS_PROPERTY = gql`
  mutation deleteResidentProperty($propertyIdId: String!) {
    deleteResidentProperty(propertyIdId: $propertyIdId) {
      success
      error
    }
  }
`;
const CREATE_RESIDENT_POPERTY = gql`
  mutation Mutation($propertyInfo: ResidentPropertyInput!) {
    insertResidentProperty(propertyInfo: $propertyInfo) {
      success
      error
      data {
        propertyId
      }
    }
  }
`;

type INSERT_RESIDENT_PROPERTY_RES = {
  insertResidentProperty: backendResponse<{
    street: string;
    unit: string | '';
    city: string;
    state: string;
    zipCode: string;
    propertyId: string;
  }>;
};

type INSERT_RESIDENT_PROPERTY_VARS = {
  propertyInfo: {
    street: string;
    unit: string | '';
    city: string;
    state: string;
    zipCode: string;
    // propertyId: string;
  };
};
type RegistrationInitialization = {
  userCommunityInfo?: any;
  getPropertyLoading?: boolean;
  getPropertyError?: string;
  role?: 'host' | 'resident';
  userProfileInfo: any;
  getProfileLoading: boolean;
  getProfileError?: string;
  residentInfo: ResidentProfileInfo;
  setResidentInfo: (obj: any) => void;
  multiSelect: boolean;
  setMultiSelect: (obj: any) => void;
  deleteProperty: (id: string) => void;
  addProperty?: (propertyInfo: any) => void;
};

export default function UseMyProfile(): RegistrationInitialization {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [multiSelect, setMultiSelect] = useState<boolean>(false);
  const [residentInfo, setResidentInfo] = useState<ResidentProfileInfo>(
    newResidentProfileInfo({}),
  );
  const {
    data: userCommunityInfo,
    loading: getPropertyLoading,
    error: getPropertyError,
  } = useQuery(GET_USER_COUMMUNITY_INFO, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getPropertiesByUser.success) {
        setResidentInfo({
          ...residentInfo,
          ...d.getPropertiesByUser.data[0],
          communityAddresses: d.getPropertiesByUser.data,
        });
      }
      if (d.getPropertiesByUser.data.length > 1) {
        setMultiSelect(true);
      }
    },
  });

  const {
    data: userProfileInfo,
    loading: getProfileLoading,
    error: getProfileError,
  } = useQuery(GET_USER_PROFILE_INFO, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.getResidentProfile.success) {
        if (d.getResidentProfile.data) {
          // setRole('resident');
          setResidentInfo({ ...residentInfo, ...d.getResidentProfile.data });
        }
      }
    },
  });
  const [doDeleteProperty] = useMutation(DELETE_ADDRESS_PROPERTY, {
    onError: e => {
      // console.log(e.message);
    },
    onCompleted: d => {
      if (d.doDeleteProperty.success) {
        // console.log(editData.communityAddr);
      }
    },
  });

  const deleteProperty = (id: string) => {
    doDeleteProperty({
      variables: {
        propertyIdId: id,
      },
    });
  };
  const [doCreateResidentProperty] = useMutation<
    INSERT_RESIDENT_PROPERTY_RES,
    INSERT_RESIDENT_PROPERTY_VARS
  >(CREATE_RESIDENT_POPERTY);

  const addProperty = (propertyInfo: any) => {
    doCreateResidentProperty({
      onError: e => {
        console.log('error', e);
      },
      variables: {
        propertyInfo: propertyInfo,
      },
    }).then((d: any) => {
      if (d?.data?.insertResidentProperty?.success) {
        propertyInfo.propertyId = d.data.insertResidentProperty.data[0].propertyId;
        setResidentInfo({
          ...residentInfo,
          communityAddresses: residentInfo.communityAddresses.concat(propertyInfo),
        });
      }
    });
  };
  return {
    userCommunityInfo: userCommunityInfo || {},
    getPropertyLoading: getPropertyLoading || false,
    getPropertyError: getPropertyError?.message || '',
    role: searchParams.get('isHost') === 'true' ? 'host' : 'resident',
    userProfileInfo: userProfileInfo || {},
    getProfileLoading: getProfileLoading || false,
    getProfileError: getProfileError?.message || '',
    residentInfo: residentInfo || {},
    setResidentInfo,
    multiSelect,
    setMultiSelect,
    deleteProperty,
    addProperty,
  };
}
