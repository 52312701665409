import React, { ReactElement, useContext, useRef, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GuestDataGrid from '../guestDataGrid/guestDataGrid';
import InviteGuestNav from '../inviteGuestNav/inviteGuestNav';
import ExportPopup from './exportPopup';
import style from './guestList.module.css';

export default function GuestList(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { featuresConfig } = useContext(CommunityContext);

  return (
    <div className={style.ListBox} ref={wrapperRef}>
      {open && <ExportPopup setOpen={setOpen} />}
      {Boolean(!featuresConfig?.host?.inviteGuest?.propertyLinkEnabled) && (
        <InviteGuestNav activeTab="list" />
      )}
      {/* <GuestListSearch /> */}
      {/*<GuestTable title="My Guests" />*/}
      <GuestDataGrid
        title="My Guests"
        fastPassesOnly={false}
        onBtnExport={() => {
          setOpen(true);
        }}
      />
      {/* <GuestTable title="Guests who have accessed the account" data={t2List} /> */}
    </div>
  );
}
