import { formatDateForInput } from '../utilities/formatDate';

export type FamilyOrFriendPassInfo = {
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  isNew?: boolean;
  arrivalDate?: string;
  departureDate?: string;
  // TODO: Remove any
  [key: string]: any;
};

export function newFamilyOrFriendPass(data: any = {}): FamilyOrFriendPassInfo {
  const res: FamilyOrFriendPassInfo = {
    firstName: '',
    lastName: '',
    address: '',
    phoneNumber: '',
    email: '',
    isNew: false,
  };
  Object.assign(res, data);
  if (res.arrivalDate) {
    res.arrivalDate = formatDateForInput(new Date(res.arrivalDate));
  }
  if (res.departureDate && (res.departureDate as unknown) instanceof Date) {
    res.departureDate = formatDateForInput(new Date(res.departureDate));
  }
  return res;
}

export default FamilyOrFriendPassInfo;
