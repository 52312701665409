import { gql } from '@apollo/client';
import {
  backendPaginatedResponse,
  backendResponse,
} from '../../../common_lib_front/types/backendResponse';

export const GET_GUESTS = gql`
  query GetMyGuests(
    $hideDeleted: Boolean
    $pageSize: Float!
    $pageNumber: Float!
    $currentOnly: Boolean
    $fastPassesOnly: Boolean
    $lastName: String
  ) {
    getMyGuests(
      hideDeleted: $hideDeleted
      pageSize: $pageSize
      pageNumber: $pageNumber
      currentOnly: $currentOnly
      fastPassesOnly: $fastPassesOnly
      lastName: $lastName
    ) {
      success
      error
      numberPages
      data {
        guest {
          userId
          firstName
          lastName
          email
          phoneNumber
        }
        guestInfo {
          userId
          firstName
          lastName
          email
          phoneNumber
        }
        registration {
          registrationId
          complete
        }
        communityRental {
          communityRentalId
          registrationId
          address
          arrivalDate
          departureDate
        }
        reservationExtension {
          fulfilled
          newEndDate
          newStartDate
          registrationId
          reservationExtensionId
        }
        passes {
          passId
          userId
          number
          startDate
          endDate
          status
          paid
          url
          passType
          passInfo {
            name
            priceInfo {
              adminRefundOnly
            }
          }
        }
      }
    }
  }
`;

export type InvitedGuestInfoType = {
  guest?: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  guestInfo?: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  registration?: {
    registrationId: string;
    complete: boolean;
  };
  communityRental?: {
    communityRentalId: string;
    registrationId: string;
    address: string;
    arrivalDate: string;
    departureDate: string;
  };
  reservationExtension: {
    reservationExtensionId: string;
    newStartDate: string | null;
    newEndDate: string | null;
    fulfilled: boolean;
    registrationId: string;
  } | null;
  passes?: Array<{
    passId: string;
    userId: string;
    number: string;
    startDate: string;
    endDate: string;
    status: string;
    paid: string;
    url?: string;
    passType: string;
    passInfo: IGPassInfo;
  }>;
};
export type GET_GUESTS_VARS = {
  hideDeleted: boolean;
  pageNumber: number;
  pageSize: number;
  currentOnly?: boolean;
  fastPassesOnly?: boolean;
  lastName?: string;
};
export type GET_GUESTS_RES = {
  getMyGuests: backendPaginatedResponse<Array<InvitedGuestInfoType>>;
};

export const GET_PASS_INFOS = gql`
  query GetPassInfosByCommunity($communityId: String!) {
    getPassInfosByCommunity(complete: true, communityId: $communityId) {
      success
      error
      data {
        passInfoId
        name
        portal
        issuableFrom
        issuableUntil
      }
    }
  }
`;
export type GET_PASS_INFOS_VARS = {
  communityId: string;
};
export type IGPassInfo = {
  issuableUntil: string | undefined;
  issuableFrom: string | undefined;
  passInfoId: string;
  name: string;
  portal: string;
  priceInfo: {
    adminRefundOnly: boolean;
  }
};
export type GET_PASS_INFOS_RES = {
  getPassInfosByCommunity: backendResponse<IGPassInfo[]>;
};
