export interface ResidentProfileInfo {
  address: string;
  city: string;
  communityCode: string;
  companyName: string;
  country: string;
  email: string;
  emergencyContact: string;
  firstName: string;
  lastName: string;
  pdkId?: number;
  phoneNumber: string;
  altPhoneNum: string;
  roles: Array<string>;
  state: string;
  status: string;
  userId: string;
  zipCode: string;
  mailingStreet: string;
  mailingUnit: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
  street: string;
  unit: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyEmail: string;
  emergencyPhoneNumber: string;
  ownerPin: string;
  propertyId: string;
  communityAddresses: Array<{ street: string; unit?: string; city?: string; state?: string; zipCode?: string; propertyId?: string }>;
}
export function newResidentProfileInfo(
  data: Partial<ResidentProfileInfo> = {},
): ResidentProfileInfo {
  return {
    address: data?.address || '',
    city: data?.city || '',
    communityCode: data?.communityCode || '',
    companyName: data?.companyName || '',
    country: data?.country || '',
    email: data?.email || '',
    emergencyContact: data?.emergencyContact || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    pdkId: data?.pdkId,
    phoneNumber: data?.phoneNumber || '',
    altPhoneNum: data?.altPhoneNum || '',
    roles: data?.roles || [],
    state: data?.state || '',
    status: data?.status || '',
    userId: data?.userId || '',
    zipCode: data?.zipCode || '',
    mailingStreet: data?.mailingStreet || '',
    mailingUnit: data?.mailingUnit || '',
    mailingZipCode: data?.mailingZipCode || '',
    street: data?.street || '',
    unit: data?.unit || '',
    emergencyFirstName: data?.emergencyFirstName || '',
    emergencyLastName: data?.emergencyLastName || '',
    emergencyEmail: data?.emergencyEmail || '',
    emergencyPhoneNumber: data?.emergencyPhoneNumber || '',
    ownerPin: data?.ownerPin || '',
    mailingCity: data?.mailingCity || '',
    mailingState: data?.mailingState || '',
    propertyId: data?.propertyId || '',
    communityAddresses: data?.communityAddresses || [],
  };
}
export default ResidentProfileInfo;
