import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import SearchSelector from '../../common_lib_front/components/searchSelector/searchSelector';
import {
  formatDateForInput,
  formatDateForInputUTC,
} from '../../common_lib_front/utilities/formatDate';
import { useGetHostRentals } from '../../hooks/useGetHostRentals';
import UseMyProfile from '../../hooks/useMyProfile';
import { passInfoDataType, useValidPassInfos } from '../../hooks/useValidPassInfos';
import CompanyNameInput from './companyNameInput';
import style from './inviteGuestForm.module.css';
import InviteGuestRentalForm from './inviteGuestRentalForm';

export type InvitePassInfo = {
  startDate: string;
  endDate: string;
  passInfoId: string;
  passId?: string;
};

export type RecipientInfo = {
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  willPayForPass: boolean;
  passes: Array<InvitePassInfo>;
  arrivalDate: string;
  departureDate: string;
  address: string;
  registrationId?: string;
  numberOfPasses?: string;
};

export type formInputConfig =
  | undefined
  | {
    required?: boolean;
    optional?: boolean;
    hidden?: boolean;
    label?: string;
  };

export type formConfigValue =
  | 'firstName'
  | 'lastName'
  | 'companyName'
  | 'email'
  | 'phoneNumber'
  | 'address'
  | 'willPayForPass'
  | 'passType';

export type formConfigType = Record<formConfigValue, formInputConfig>;

export type formTextConfigType = {
  recipientTag: string;
  recipientMessage?: string;
  largeNumberWarning?: string;
};

type InviteGuestFormProps = {
  formConfig: formConfigType;
  formTextConfig: formTextConfigType;
  idx: number;
  data: RecipientInfo;
  paymentMode?: boolean;
  editRecipient: (data: Partial<RecipientInfo>) => void;
  editRental: (idx: number, data: Partial<InvitePassInfo>) => void;
  deleteRecipient: () => void;
  deleteRental?: (rentalIdx: number) => void;
  addRental?: () => void;
  disabled?: boolean;
  targetPortal?: 'guest' | 'vendor';
  role?: 'resident' | 'host';
};
export function InviteGuestForm(props: InviteGuestFormProps): ReactElement {
  const {
    formConfig,
    formTextConfig,
    idx,
    data,
    editRecipient,
    editRental,
    deleteRecipient,
    deleteRental,
    addRental,
    paymentMode,
    disabled,
    targetPortal,
    role,
  } = props;

  const { communityId } = useContext(CommunityContext);
  const formLayout = style.formLayout;

  const invalidEmailRegex = /@(guest\.booking|guest\.airbnb|relay\.vrbo)\.com/i;
  const { validPassInfoDatas, allPassInfoDatas, specifficPassInfoData } =
    useValidPassInfos({
      portal: targetPortal,
      specifficPassInfoId: data.passes[0]?.passInfoId,
      startDate: data.arrivalDate,
      endDate: data.departureDate,
      filters: [p => p.durationInfo.type !== 0],
      filterDateRange: true,
      filterIssuable: true,
      specifficPassMustBeInFilter: true,
    });

  const { residentInfo } =  UseMyProfile();

  const { data: myRentals } = useGetHostRentals({
    variables: {
      hostInfoId: null,
      approvedOnly: true,
    },
  });

  useEffect(() => {
    if (role !== 'resident' && myRentals?.getRentalsByHost.data?.length === 1 && data.address === '') {
      const defaultAddress = myRentals.getRentalsByHost.data[0].address;
      if (!defaultAddress) return;
      editRecipient({
        address: defaultAddress,
      });
    }
  }, [myRentals, data, editRecipient]);

  const validateAddress = useCallback(
    async (partial: string): Promise<string[]> =>
      myRentals?.getRentalsByHost.data
        ?.filter(rental => rental.address.includes(partial))
        .map(rental => rental.address) || [],
    [myRentals],
  );

  const fetchResidentAddress = useCallback(
    async (partial: string): Promise<string[]> =>
      residentInfo?.communityAddresses
        ?.filter(address => address.street.includes(partial))
        .map(address => address.street) || [],
    [residentInfo],
  );

  return (
    <div className={style.formBox}>
      <div className={style.recipientTitle}>
        {/* {location.pathname.includes('fast-pass') ? 'Pass' : 'Reservation'} {idx + 1} */}
        {formTextConfig.recipientTag} {idx + 1}
      </div>
      <div className={formLayout}>
        {!!formTextConfig.recipientMessage && (
          <div className={style.instruction}>
            {formTextConfig.recipientMessage}
            {/* {location.pathname.includes('fast-pass') ? (
              'To issue a fast pass, please select from your approved rentals list and enter an arrival date and departure date.'
            ) : (
              <>
                {communityId === 'sipoa'
                  ? 'To invite a rental guest '
                  : 'To invite a short-term rental guest '}
                please enter a phone number and email address (email is optional). Your
                invite will be sent by text and, if provided, email.
              </>
            )} */}
          </div>
        )}
        <div className={style.guestInfo}>
          {(!formConfig.firstName?.hidden || !formConfig.lastName?.hidden) && (
            <div className={style.inputShortBox}>
              {!formConfig.firstName?.hidden && (
                <div className={style.inputShort}>
                  <InputField
                    htmlFor={`recipient-f-name-${idx}`}
                    disabled={disabled}
                    labelTitle={`${formConfig.firstName?.label || 'First Name'}${formConfig.firstName?.optional ? ' (Optional)' : ''
                    }`}
                    inputValue={data.firstName}
                    changeHandler={e => {
                      editRecipient({ firstName: e.target.value });
                    }}
                    required={formConfig.firstName?.required}
                    smallSize
                    highlightOnFocus
                  />
                </div>
              )}
              {!formConfig.lastName?.hidden && (
                <div className={style.inputShort}>
                  <InputField
                    htmlFor={`recipient-l-name-${idx}`}
                    disabled={disabled}
                    labelTitle={`${formConfig.lastName?.label || 'Last Name'}${formConfig.lastName?.optional ? ' (Optional)' : ''
                    }`}
                    inputValue={data.lastName}
                    changeHandler={e => {
                      editRecipient({ lastName: e.target.value });
                    }}
                    required={formConfig.lastName?.required}
                    smallSize
                    highlightOnFocus
                  />
                </div>
              )}
            </div>
          )}
          {!formConfig.companyName?.hidden && (
            <div className={style.companyNameInpt}>
              <CompanyNameInput
                htmlFor={`company-name-${idx}`}
                title={`${formConfig.companyName?.label || 'Business Name'}${formConfig.companyName?.optional ? ' (Optional)' : ''
                }`}
                value={data.companyName}
                changeHandler={val =>
                  editRecipient({
                    companyName: val,
                    companyId: '',
                  })
                }
                selectHandler={val =>
                  editRecipient({
                    companyId: val.companyInfoId,
                    companyName: val.companyName,
                    email: val.email || '',
                    phoneNumber: val.businessPhone,
                  })
                }
                highLightOnFocus
                required={formConfig.companyName?.required}
              />
            </div>
          )}
          {!formConfig.email?.hidden && (
            <InputField
              htmlFor={`recipient-email-${idx}`}
              disabled={disabled}
              labelTitle={`${formConfig.email?.label || 'Email Address'}${formConfig.email?.optional ? ' (Optional)' : ''
              }`}
              inputValue={data.email}
              changeHandler={e => {
                if (invalidEmailRegex.test(e.target.value)) {
                  e.target.setCustomValidity(
                    'Vacation Rental Platform email addresses are not accepted. Please provide a different email address or invite by phone number only.',
                  );
                  e.target.reportValidity();
                } else {
                  e.target.setCustomValidity('');
                }
                editRecipient({ email: e.target.value });
              }}
              required={formConfig.email?.required}
              smallSize
              highlightOnFocus
              inputType="email"
            />
          )}
          <PhoneInputWrapper
            disabled={disabled}
            inputTitle={`${formConfig.phoneNumber?.label || 'Phone Number'}${formConfig.phoneNumber?.optional ? ' (Optional)' : ''
            }`}
            value={data.phoneNumber}
            changeHandler={e => {
              editRecipient({ phoneNumber: e });
            }}
            smallStandardInput
            required={formConfig.phoneNumber?.required}
          />
          <div className={style.inputShortBox}>
            <div className={style.inputShort}>
              <InputField
                htmlFor={`recipient-${idx}-arrival`}
                labelTitle="Arrival Date"
                inputType="date"
                disabled={disabled}
                max={(() => {
                  if (specifficPassInfoData?.durationInfo.maxStartDate) {
                    return formatDateForInputUTC(
                      specifficPassInfoData.durationInfo.maxStartDate,
                    );
                  }
                })()}
                inputValue={data.arrivalDate}
                changeHandler={e => {
                  //setPassTypeOnDate(e.target.value, data.departureDate);
                  editRecipient({ arrivalDate: e.target.value });
                  data.passes.forEach((pass, pIdx) => {
                    editRental(pIdx, { startDate: e.target.value });
                  });
                }}
                min={
                  specifficPassInfoData?.durationInfo.minStartDate
                    ? formatDateForInput(
                      specifficPassInfoData.durationInfo.minStartDate, // eslint-disable-line indent
                    ) // eslint-disable-line indent
                    : formatDateForInput(new Date())
                }
                required
                smallSize
                highlightOnFocus
              />
            </div>
            <div className={style.inputShort}>
              <InputField
                htmlFor={`recipient-${idx}-departure`}
                labelTitle="Departure Date"
                inputType="date"
                disabled={disabled}
                min={(() => {
                  if (specifficPassInfoData?.durationInfo.minEndDate) {
                    return formatDateForInput(
                      specifficPassInfoData.durationInfo.minEndDate,
                    );
                  }
                  if (data.arrivalDate) {
                    return data.arrivalDate;
                  }
                  return formatDateForInput(new Date());
                })()}
                inputValue={data.departureDate}
                max={
                  specifficPassInfoData?.durationInfo.maxEndDate &&
                  formatDateForInputUTC(
                    specifficPassInfoData?.durationInfo.maxEndDate, // eslint-disable-line indent
                  ) // eslint-disable-line indent
                }
                changeHandler={e => {
                  //setPassTypeOnDate(data.arrivalDate, e.target.value);
                  editRecipient({ departureDate: e.target.value });
                  data.passes.forEach((pass, pIdx) => {
                    editRental(pIdx, { endDate: e.target.value });
                  });
                }}
                required
                smallSize
                highlightOnFocus
              />
            </div>
          </div>
          {!formConfig.address?.hidden && (
            <div className={style.searchInput}>
              <SearchSelector
                htmlFor={`recipient-${idx}-address`}
                // title="Rental Address of Guest's Stay"
                title={`${formConfig.address?.label || 'Address'}${formConfig.address?.optional ? ' (Optional)' : ''
                }`}
                value={data.address}
                fetch={role === 'resident' 
                  ? fetchResidentAddress 
                  : validateAddress}
                highLightOnFocus
                noMatchAlert="Please select an approved address"
                addressValues={role === 'resident' ? residentInfo?.communityAddresses.map(address => address.street) : myRentals?.getRentalsByHost.data?.map(r => r.address)}
                changeHandler={val => editRecipient({ address: val })}
                disabled={disabled}
              />
            </div>
          )}
        </div>
        <div className={style.rightBox}>
          <InviteGuestRentalForm
            idx={idx}
            role={role}
            data={data}
            formConfig={formConfig}
            paymentMode={paymentMode}
            editRecipient={editRecipient}
            editRental={editRental}
            deleteRecipient={deleteRecipient}
            deleteRental={deleteRental}
            addRental={addRental}
            disabled={disabled}
            targetPortal={targetPortal}
          />
          {/* prettier-ignore */}
          {/* eslint-disable-next-line */}
          {/* {communityId !== 'grandcomplex' || location.pathname.includes('fast-pass') || paymentMode ? null : ( */}
          {!formConfig.willPayForPass?.hidden && (
            <div className={style.check}>
              <InputField
                htmlFor={`recipient-${idx}-will-pay`}
                labelTitle={
                  formConfig.willPayForPass?.label ||
                  "Check here to pay for your Guest's Pass"
                }
                inputType="checkbox"
                disabled={paymentMode}
                checked={!paymentMode ? data.willPayForPass : true}
                changeHandler={e => {
                  editRecipient({ willPayForPass: e.target.checked });
                }}
              />
            </div>
          )}
        </div>
        {paymentMode || idx === 0 ? (
          <div className={style.delBoxHidden}>
            <button
              type="button"
              onClick={() => {
                deleteRecipient();
              }}
              className={style.delBtn}
              disabled={disabled}
            >
              x
            </button>
          </div>
        ) : (
          <div className={style.delBox}>
            <button
              type="button"
              onClick={() => {
                deleteRecipient();
              }}
              className={style.delBtn}
              disabled={disabled}
            >
              x
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
InviteGuestForm.defaultProps = {
  addRental: undefined,
  deleteRental: undefined,
  paymentMode: false,
  passInfoData: undefined,
  recipientType: 'guest',
};
