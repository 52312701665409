import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../../../common_lib_front/components/popUp/popUp';
import { backendResponse } from '../../../../../common_lib_front/types/backendResponse';
import {
  passStatusMapTitles,
  paymentStatusMapTitles,
} from '../../../../../common_lib_front/types/passInfo';
import { formatDate } from '../../../../../common_lib_front/utilities/formatDate';
import ActionsBtn from '../../../../../components/actionsBtn/actionsBtn';
import useGetMyGuests from '../../../../../hooks/useGetMyguests';
import { classNames } from '../../../../../utils/css';
import style from './guestDetails.module.css';

const REFUND_PASS = gql`
  mutation RefundPass($passId: String!) {
    refundPass(passId: $passId) {
      error
      success
    }
  }
`;
type REFUND_PASS_VARS = {
  passId: string;
};
type REFUND_PASS_RES = {
  refundPass: backendResponse<undefined>;
};

type GuestDetailsProps = {
  refundMode?: boolean;
  children?: ReactElement;
};
export default function GuestDetails(props: GuestDetailsProps): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  const { refundMode, children } = props;
  const { data } = useGetMyGuests({
    registrationIds: [registrationId],
    pageSize: 0,
  });
  const guest = useMemo(
    () => data?.find(g => g.registration?.registrationId === registrationId),
    [data, registrationId],
  );
  console.log(guest);
  const history = useHistory();
  const [checked, setChecked] = useState<Record<number, boolean>>({});
  const [alert, setAlert] = useState<string>('');
  const [doRefundPass, { loading: refundLoading }] = useMutation<
    REFUND_PASS_RES,
    REFUND_PASS_VARS
  >(REFUND_PASS, {
    onCompleted: d => {
      if (d.refundPass.success) {
        setAlert('Pass Successfully Refunded');
        setChecked({});
      } else if (d.refundPass.error) {
        setAlert(d.refundPass.error);
      }
    },
    onError: error => {
      console.log(error);
      setAlert('Something went wrong. Refund may not have been issued correctly.');
    },
  });
  function getPassStyles<T extends { status: string }>(pass: T) {
    const classes = [style.statusBox];
    switch (pass.status) {
      case 'active':
        classes.push(style.active);
        break;
      case 'inactive':
        classes.push(style.readyToScan);
        break;
      case 'incomplete':
        classes.push(style.incomplete);
        break;
      case 'expired':
        classes.push(style.expired);
        break;
      case 'refunded':
        classes.push(style.refunded);
        break;
      case 'incomplete-rental-car':
        classes.push(style.incompleteRentalCar);
        break;
    }
    return classNames(...classes);
  }

  // function formatPassStatus(status: string) {
  //   if (status === 'inactive') {
  //     return 'Ready to Scan';
  //   }
  //   if (status === 'incomplete') {
  //     return 'Incomplete';
  //   }
  //   if (status === 'incomplete-rental-car') {
  //     return 'Incomplete Rental Car';
  //   }
  //   return status;
  // }

  console.log(guest);
  // console.log(checked);
  return (
    <PopUp
      largeSize
      close={() => history.push('/invite-guest/guest-list')}
      title={`${guest?.guestInfo?.firstName} ${guest?.guestInfo?.lastName}`}
    >
      <div className={style.box}>
        <div className={style.guestInfoBox}>
          <div className={style.guestHeader}>Guest Information</div>
          <table className={style.guestTable}>
            <tbody>
              <tr>
                <th>Name</th>
                <td>{`${guest?.guestInfo?.firstName} ${guest?.guestInfo?.lastName}`}</td>
              </tr>
              <tr>
                <th>Email Address</th>
                <td className={style.breakWord}>{guest?.guestInfo?.email}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{guest?.guestInfo?.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.boxRight}>
          <div className={style.borderBox}>
            <GenericAlert title={alert} color="red" hidden={!alert} />
            <table width="100%" cellSpacing="0px" className={style.table}>
              <thead className={`${style.tableHeader} `}>
                <tr>
                  {refundMode && (
                    <th className={style.checkboxTh}>
                      <input
                        className={style.input}
                        type="checkbox"
                        onChange={e => {
                          const res: typeof checked = {};
                          guest?.passes?.forEach((_, i) => {
                            res[i] = e.target.checked;
                            // TODO: This condition shoudl be based on dates and whether or
                            // not pass has been paid, not pass status
                            // - Parker
                            // if (!['active'].includes(guest.passes?.[i]?.status || '')) {
                            //   res[i] = false;
                            // }
                          });
                          setChecked(res);
                        }}
                      />
                    </th>
                  )}
                  <th className={style.tHeaderItem}>Rental Address</th>
                  <th className={style.tHeaderItem}>Arrival Date</th>
                  <th className={style.tHeaderItem}>Departure Date</th>
                  <th className={style.tHeaderItem}>Pass Type</th>
                  <th className={style.tHeaderItem}>Pass Status</th>
                  <th className={style.tHeaderItem}>Payment Status</th>
                  <th className={style.tHeaderItem}>&#160;</th>
                </tr>
              </thead>
              <tbody>
                {guest?.passes?.map((pass, idx) => (
                  <tr className={`${style.sheet} white`} key={idx}>
                    {refundMode && (
                      <th className={style.checkBoxTd}>
                        <input
                          className={style.input}
                          type="checkbox"
                          checked={!!checked[idx]}
                          // TODO: This condition shoudl be based on dates and whether or
                          // not pass has been paid, not pass status
                          // -Parker
                          // eslint-disable-next-line max-len
                          disabled={false} // {!['active', 'inactive'].includes(guest?.passes?.[idx]?.status || '')}
                          onChange={e => {
                            setChecked(val => ({ ...val, [idx]: e.target.checked }));
                          }}
                        />
                      </th>
                    )}
                    <td className={style.item}>{guest.communityRental.address}</td>
                    <td className={style.item}>
                      {guest.communityRental.arrivalDate &&
                        formatDate(new Date(guest.communityRental.arrivalDate))}
                    </td>
                    <td className={style.item}>
                      {guest.communityRental.departureDate &&
                        formatDate(new Date(guest.communityRental.departureDate))}
                    </td>
                    <td className={style.item}>
                      {guest.passes?.[idx]?.passInfo?.name || ''}
                    </td>
                    <td className={style.item}>
                      <div className={getPassStyles(pass)}>
                        {passStatusMapTitles[pass.status]}
                      </div>
                    </td>
                    <td className={style.item}>
                      {paymentStatusMapTitles[guest.passes?.[idx]?.paid || ''] || ''}
                    </td>
                    <td className={style.btnAction}>
                      <ActionsBtn>
                        <div className={style.actionsBtnItems}>
                          <Link to={`/invite-guest/edit/${registrationId}`}>
                            <button>Edit</button>
                          </Link>
                          <Link to={`/invite-guest/refund/${registrationId}`}>
                            <button>Refund</button>
                          </Link>
                        </div>
                      </ActionsBtn>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {Object.values(checked).some(val => val) && (
            <div className={style.refundButtonContainer}>
              <div className={style.refundBtn}>
                <Link to={`/invite-guest/details/${registrationId}`}>
                  <GenericButton
                    title="Cancel"
                    color="white"
                    outline="small"
                    size="large"
                    disabled={refundLoading}
                    clickHandler={() => {
                      setChecked([]);
                    }}
                  />
                </Link>
              </div>
              <div className={style.refundBtn}>
                <GenericButton
                  title="Refund"
                  color="blue"
                  outline="small"
                  size="large"
                  disabled={refundLoading}
                  clickHandler={() => {
                    const passes: string[] = [];
                    Object.entries(checked).forEach(([idx, isChecked]) => {
                      const pid = guest?.passes?.[Number(idx)]?.passId;
                      if (isChecked && !!pid) {
                        passes.push(pid);
                      }
                    });
                    doRefundPass({
                      variables: {
                        passId: passes?.[0],
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    </PopUp>
  );
}
GuestDetails.defaultProps = {
  refundMode: false,
  children: undefined,
};
